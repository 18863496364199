<script setup>
import {CheckIcon} from '@heroicons/vue/20/solid'
import {EnvelopeIcon, PaperAirplaneIcon, PhoneIcon} from "@heroicons/vue/24/outline";
import {onMounted, reactive, ref} from "vue";
import {useForm} from "@inertiajs/inertia-vue3";
import Contact_subform from "./contact_subform.vue";
import Aircraft_info_subform from "./Aircraft_info_subform.vue";
import DealLineItems from "./DealLineItems.vue";
import PrimaryButton from "../PrimaryButton.vue";
import HsRegisterAircraft from "./hs-register-aircraft.vue";


let currentIndex = ref(0)



const steps = [
    reactive({ id: '01', name: 'Personal Details', description: 'Tell us about you', href: '#', status: 'current' }),
    reactive({ id: '02', name: 'Aircraft Details', description: 'Tell us about your aircraft', href: '#', status: 'upcoming' }),
    reactive({ id: '03', name: 'Project Details', description: 'Help us get you started', href: '#', status: 'upcoming' }),
    reactive({ id: '04', name: 'Summary', description: '"Line up and wait"', href: '#', status: 'upcoming' }),
]


const form = useForm({
    name: '',
    last_name: '',
    shippingAddressLine1: '',
    shippingAddressLine2: '',
    shippingCity: '',
    shippingState: '',
    shippingZip: '',
    tailNumber:'',
    make:'',
    model:'',
    serialNumber:'',
    numberOfEngines: 1,
    engineType: 'Piston',
    processing: false,
    email: '',
    phoneNumber: '',
    hardcopyBackup: false,
    trackingItemSetup: false,
    conciergeServices: false,
    notes: ''
});

const setIndex = (index) => {
    currentIndex.value = index
    if (index === 0){
        steps[0].status = 'current'
        steps[1].status = 'upcoming'
        steps[2].status = 'upcoming'
        steps[3].status = 'upcoming'
    } else if (index === 1) {
        steps[0].status = 'complete'
        steps[1].status = 'current'
        steps[2].status = 'upcoming'
        steps[3].status = 'upcoming'
    } else if (index === 2) {
        steps[0].status = 'complete'
        steps[1].status = 'complete'
        steps[2].status = 'current'
        steps[3].status = 'upcoming'
    } else {
        // also create hubspot meeting

        steps[0].status = 'complete'
        steps[1].status = 'complete'
        steps[2].status = 'complete'
        steps[3].status = 'current'

        // can't render hubspot till this tab is shown
        setTimeout(function(){
          window.hbspt.meetings.create('#hubSpotMeeting')
        }, 10);

    }
}

const contactID = ref(null);
const acInfo = ref(null);
const providedTailNumber = ref(null);
const setAircraftInfo = (aircraftInfo, tailNumber) => {
  console.log('setAircraftInfo',aircraftInfo,tailNumber)
  acInfo.value = aircraftInfo;
  providedTailNumber.value = (tailNumber).toUpperCase();
}

const contactInfo = ref(null);
const showRegisterForm = ref(false);
const setContactID = (data) => {
    contactID.value = data.contact_id;
    providedTailNumber.value = data.tail_number;
    contactInfo.value = data.contact_form;
    setIndex(1);
}

const dealID = ref(null);
const submittedACInfo = ref(null);

const aircraftFormSubmitted = (info) => {
  console.log('aircraftFormSubmitted', info.submittedAircraftInfo)
  dealID.value = info.dealID;
  submittedACInfo.value = info.submittedAircraftInfo;
  setIndex(2);
}

const finishedDealItems = () => {
  console.log('finishedDealItems')
  setIndex(3);
}

const countries = ref(null);
const tosVersion = ref(null);

const meetingScript = document.createElement('script');
onMounted(()=>{

  // get countries
  axios.get(route('list-public-countries')).then(response => {
    countries.value = response.data;
  });
  // get TOS version
  axios.get(route('get-tos-version')).then(response => {
    tosVersion.value = response.data;
  });

  meetingScript.setAttribute('charset', 'utf-8');
  meetingScript.setAttribute('src', 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js')

  meetingScript.addEventListener("load", () => {

    if (window.hbspt) {

      // moved to when panel is actually shown
      //window.hbspt.meetings.create("#hubSpotMeeting")
    }
  })

  document.head.appendChild(meetingScript)

})

</script>

<template>
    <!-- Contact section -->
    <section class="relative " aria-labelledby="contact-heading">
        <div class="absolute h-1/2 w-full " aria-hidden="true"/>
        <!-- Decorative dot pattern -->
<!--        <div class="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 opacity-20">
            <svg
                class="absolute top-0 right-0 z-0 -translate-y-16 translate-x-1/2 transform sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
                width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                <defs>
                    <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20"
                             patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" class="text-logix-green" fill="currentColor"/>
                    </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"/>
            </svg>
        </div>-->
        <!-- end Decorative dot pattern -->
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="shadow-2xl shadow-logix-green relative border-logix-green border-4 shadow-xl rounded-lg ">
                <h2 id="contact-heading" class="sr-only">Sign-Up</h2>

                <div class="grid grid-cols-1 lg:grid-cols-2 py-5 ">

                    <div class="py-2 px-2 sm:px-10 lg:col-span-2 xl:p-12">
                        <!-- wizard -->
                        <div class="lg:border-t lg:border-b lg:border-logix-green">
                            <nav class="mx-auto" aria-label="Progress">
                                <ol role="list" class=" rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-logix-green">
                                    <li v-for="(step, stepIdx) in steps" :key="step.id" class="relative lg:flex-1" >
                                        <div :class="[stepIdx === 0 ? 'border-b-0 rounded-t-md' : '', stepIdx === steps.length - 1 ? 'border-t-0 rounded-b-md' : '', 'border border-gray-200  lg:border-0']">
                          <a v-if="step.status === 'complete'" :href="step.href" class="cursor-default">
                              <span class="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-logix-green lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true" />
                              <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'px-6 py-5 flex items-start text-sm font-medium']">

                                <span class="flex-shrink-0">
                                  <span class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-900">
                                    <CheckIcon class="h-6 w-6 text-logix-green" aria-hidden="true" />
                                  </span>
                                </span>
                                <span class="mt-0.5 ml-4 flex min-w-0 flex-col">
                                  <span class="text-sm font-medium text-logix-green">{{ step.name }}</span>
                                  <span class="text-sm font-medium text-gray-300">{{ step.description }}</span>
                                </span>
                              </span>
                          </a>
                        <a v-else-if="step.status === 'current'" :href="step.href" aria-current="step" class="cursor-default">
                            <span class="absolute top-0 left-0 h-full w-1 bg-logix-dark-blue lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true" />
                            <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'px-6 py-5 flex items-start text-sm font-medium']">
                              <span class="flex-shrink-0">
                                <span class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-logix-dark-blue">
                                  <span class="text-logix-green">{{ step.id }}</span>
                                </span>
                              </span>
                              <span class="mt-0.5 ml-4 flex min-w-0 flex-col">
                                <span class="text-sm font-medium text-logix-green">{{ step.name }}</span>
                                <span class="text-sm font-medium text-gray-300">{{ step.description }}</span>
                              </span>
                            </span>
                        </a>
                        <a v-else :href="step.href" class="cursor-default">
                            <span class="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-logix-green lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true" />
                            <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'px-6 py-5 flex items-start text-sm font-medium']">
                              <span class="flex-shrink-0">
                                <span class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                                  <span class="text-gray-500">{{ step.id }}</span>
                                </span>
                              </span>
                              <span class="mt-0.5 ml-4 flex min-w-0 flex-col">
                                <span class="text-sm font-medium text-gray-500">{{ step.name }}</span>
                                <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                              </span>
                            </span>
                                            </a>
                                            <template v-if="stepIdx !== 0">
                                                <!-- Separator -->
                                                <div class="absolute inset-0 top-0 left-0 hidden w-3 lg:block" aria-hidden="true">
                                                    <svg class="h-full w-full text-gray-300" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                                                        <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
                                                    </svg>
                                                </div>
                                            </template>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <!-- begin form content -->
                        <div class="mt-1 border-2 border-logix-green min-h-100 p-8 ">
                            <div v-if="currentIndex === 0">
                                <!-- begin personal info -->
                                <div class="mt-10 sm:mt-0">
                                    <div class="md:grid md:grid-cols-3 md:gap-6">
                                        <div class="md:col-span-1">
                                            <div class="px-4 sm:px-0">
                                                <h3 class="text-lg font-medium leading-6 text-white font-bold">Personal Information</h3>
                                                <p class="mt-1 text-sm text-gray-400">Serious inquires only.</p>
                                                <p class="mt-2 text-sm text-gray-400">All form fields are required to be filled in to ensure we can provide you with the best service possible.</p>
                                            </div>
                                        </div>
                                        <div class="mt-5 md:col-span-2 md:mt-0">
                                            <contact_subform
                                                @aircraftInfoCollected="setAircraftInfo"
                                                @contactFormSubmitted="setContactID"
                                            ></contact_subform>
                                        </div>
                                    </div>
                                </div>
                                <!-- end personal info -->
                            </div>
                            <div v-if="currentIndex === 1">
                                <!-- begin aircraft info -->
                                <div class="mt-10 sm:mt-0">
                                    <div class="md:grid md:grid-cols-3 md:gap-6">
                                        <div class="md:col-span-1">
                                            <div class="px-4 sm:px-0">
                                                <h3 class="text-lg font-medium leading-6 text-white font-bold" v-if="providedTailNumber">
                                                  {{ providedTailNumber }} Information</h3>
                                                <h3 class="text-lg font-medium leading-6 text-white font-bold" v-else>Aircraft Information</h3>
                                                <div v-if="acInfo">

                                                  <p class="mt-1 text-sm text-gray-400">Tail Number: N{{acInfo.tail_number}}</p>
                                                  <p class="mt-1 text-sm text-gray-400">Year of Manufacture: {{acInfo.year_mfr}}</p>
                                                  <p class="mt-1 text-sm text-gray-400">Manufacturer: {{acInfo.mfr}}</p>
                                                  <p class="mt-1 text-sm text-gray-400">Model: {{acInfo.model}}</p>
                                                  <p class="mt-1 text-sm text-gray-400">Serial Number: {{acInfo.serial_number}}</p>
                                                  <p class="mt-1 text-sm text-gray-400">Engine Type: {{acInfo.faa_engine_type}}</p>

                                                  <div class="mt-5">
                                                    <p class="mt-1 text-sm text-gray-400">Owner: {{acInfo.name}}</p>
                                                    <p class="mt-1 text-sm text-gray-400">Street: {{acInfo.street}}</p>
                                                    <p class="mt-1 text-sm text-gray-400">City: {{acInfo.city}}</p>
                                                    <p class="mt-1 text-sm text-gray-400">State: {{acInfo.state}}</p>
                                                    <p class="mt-1 text-sm text-gray-400">Zip: {{acInfo.zip_code}}</p>
                                                  </div>

                                                  <p class="mt-1 text-sm text-gray-400">Registrant Type: {{acInfo.type_registrant}}</p>
<!--                                                  <p class="mt-1 text-sm text-gray-400">Info: {{acInfo}}</p>-->
                                                </div>

                                            </div>
                                        </div>
                                        <div class="mt-5 md:col-span-2 md:mt-0">
                                          <aircraft_info_subform
                                              :aircraft-info="acInfo"
                                              :provided-tail-number="providedTailNumber"
                                              :hs_contact_id="contactID"
                                              @aircraftFormSubmitted="aircraftFormSubmitted"
                                          />
                                        </div>
                                    </div>
                                </div>
                                <!-- end aircraft info -->
                            </div>
                            <div v-if="currentIndex === 2">
                                <!-- begin aircraft info -->
                                <div class="mt-10 sm:mt-0">
                                    <div class="md:grid md:grid-cols-3 md:gap-6">
                                        <div class="md:col-span-1">
                                            <div class="px-4 sm:px-0">
                                                <h3 class="text-lg font-medium leading-6 text-white font-bold">Project Details</h3>
                                                <p class="mt-1 text-sm text-gray-400">Learn about the customizations you can make to your aircraft digitization project.</p>
                                            </div>
                                        </div>
                                        <div class="mt-5 md:col-span-2 md:mt-0">
                                          <deal-line-items
                                              :submitted-aircraft-info="submittedACInfo"
                                              @lineItemFormSubmitted="finishedDealItems"
                                          />
                                        </div>
                                    </div>
                                </div>
                                <!-- end aircraft info -->
                            </div>
                            <div v-if="currentIndex === 3">
                                <!-- begin meeting -->
                                <div class="mt-10 sm:mt-0">
                                    <div class="w-100">
                                        <div class="mt-5 md:col-span-2 md:mt-0">
                                          <h3 class="text-lg font-medium leading-6 text-white font-bold">Summary</h3>
                                          <p class="mt-1 text-sm text-gray-400">Thanks for taking the time to provide us with your information! Feel free to schedule a meeting with Rob to learn more about what PLANELOGIX has to offer you and your aircraft ownership experience.</p>
                                            <div class=" shadow sm:rounded-md">
                                                <div class="px-4 py-5 sm:p-6">
                                                  <div id="hubSpotMeeting" v-once class="meetings-iframe-container" data-src="https://meetings.hubspot.com/wgoldstein?embed=true"></div>
                                                </div>
                                            </div>
                                          <div class="text-center">
                                            <p class="text-sm text-gray-400">Don't want to wait? Request account activation below.</p>
                                            <primary-button
                                                class="mt-5"
                                                @click="showRegisterForm = true"
                                                >Request Account Activation</primary-button>
                                            <hs-register-aircraft
                                                v-if="showRegisterForm"
                                                :version="tosVersion"
                                                :countries="countries"
                                                :aircraft-info="submittedACInfo"
                                                :contact-info="contactInfo"
                                              ></hs-register-aircraft>
                                          </div>

                                        </div>
                                    </div>
                                </div>
                                <!-- end aircraft info -->
                            </div>
                        </div>
                        <!-- end form content -->

                        <!-- end wizard -->
                    </div>
                </div>

                <!-- footer -->
                <footer class="bg-white pb-2 pt-2 rounded-b-md">
                    <div class="relative">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-300" />
                        </div>
                        <div class="relative flex justify-center">
                          <span class="isolate inline-flex -space-x-px rounded-md shadow-sm">
                            <a href="tel:+19199039390" class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-logix-green-500 focus:outline-none focus:ring-1 focus:ring-logix-green-500">
                              <span class="sr-only">Call</span>
                              <PhoneIcon class="h-5 w-5 mr-2" aria-hidden="true"/> (919) 903-9390

                            </a>
                            <a href="mailto:scans@planelogix.com?subject=Let's Get Started" class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-logix-green-500 focus:outline-none focus:ring-1 focus:ring-logix-green-500">
                              <span class="sr-only">Email</span>
                              <EnvelopeIcon class="h-5 w-5  mr-2" aria-hidden="true" /> scans@planelogix.com
                            </a>
                            <button type="button" class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-logix-green-500 focus:outline-none focus:ring-1 focus:ring-logix-green">
                              <span class="sr-only">Fly</span>
                              <PaperAirplaneIcon class="h-5 w-5 mr-2" aria-hidden="true" /> KRDU // KTTA // KBUY
                            </button>
                          </span>
                        </div>
                    </div>
                </footer>
                <!-- end footer -->
            </div>
        </div>
    </section>
</template>
