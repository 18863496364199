<template>
  <div class=" shadow sm:rounded-md">
    <div class="bg-white px-4 py-5 sm:p-6">
      <form @submit.prevent="addLineItems()" id="hs_deal_lineitems">

        <div class="col-span-6 sm:col-span-3">
          <h2>Include Physical Reorganization Services in Quote?</h2>
          <InputLabel value="Request to have our seasoned and trained staff take a look at your maintenance records and propose an estimate to clean up your maintenance records. Documents we deem no-longer relevant will be organized and removed from your current records. They will be sent back to you, along with your newly organized records, for your review. These services are billed at $125/hr. Additional binders and hardware available upon request." />
          <div class="relative flex mt-2">
            <div class="flex h-5 items-center">
              <Checkbox name="add_physical_reorg" v-model="line_item_form.add_physical_reorg" :checked="line_item_form.add_physical_reorg" :disabled="line_item_form.processing" />
            </div>
            <div class="ml-3 text-sm">
              <label v-if="line_item_form.add_physical_reorg" class="font-medium text-gray-600 dark:text-slate-400">Include Physical Reorg. Services</label>
              <label v-else class="font-medium text-gray-600 dark:text-slate-400">Do Not Include Physical Reorg. Services</label>
            </div>
          </div>
          <div v-if="line_item_form.add_physical_reorg" class="mt-2">
            <img src="https://logixpublic.s3.amazonaws.com/images/physicalreorg.png" class="px-5 shadow-md"/>
            <p class="mt-2 text-sm">At your option, we can clean up, reorganize, and reconstitute your records into fresh binders, each with it's own series of tabs and organization.</p>
          </div>
        </div>
        <hr class="w-100 col-span-6 my-5">
        <div class="col-span-6 sm:col-span-3">
          <h2>Include Hardcopy Executive Binder In Quote?</h2>
          <InputLabel value="An elegant solution to bridging the gap between the physical and digital world. Customize your binder's data-plate and keep an FAA compliant printed backup of your records. Your records will include reports, your current scanned maintenance records, transcribed records, and tabular organization. This $499.95 option is well worth the peace of mind." />
          <div class="relative flex mt-2">
            <div class="flex h-5 items-center">
              <Checkbox name="add_physical_reorg" v-model="line_item_form.add_hardcopy_binder" :checked="line_item_form.add_hardcopy_binder" :disabled="line_item_form.processing" />
            </div>
            <div class="ml-3 text-sm">
              <label v-if="line_item_form.add_hardcopy_binder" class="font-medium text-gray-600 dark:text-slate-400">Include Hardcopy Binder Option</label>
              <label v-else class="font-medium text-gray-600 dark:text-slate-400">Do Not Include Hardcopy Binder Option</label>
            </div>
          </div>
        </div>
        <div v-if="line_item_form.add_hardcopy_binder">
          <div class="col-span-6">
            <p class="font-bold text-center my-2">Customize Your Data-Plate Below</p>
            <!--  text-[1.9rem] woorks pretty well for a fontsize too -->
            <div class="text-center h-[168px] grid grid-row justify-center gap-1 pt-8 pb-5 text-xl w-full" :style="{ backgroundImage: 'url(' + binderPlateImage + ')',backgroundRepeat:'no-repeat', backgroundPosition:'center' , fontFamily:'Helvetica', fontSize:'36.88px'}">
              <p class="text-white">{{ line_item_form.namePlateLineOne }}</p>
              <p class="text-white">{{ line_item_form.namePlateLineTwo }}</p>
              <p class="text-white">{{ line_item_form.namePlateLineThree }}</p>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-3 mt-2">
            <InputLabel for="make" value="Data-Plate Line 1"/>
            <TextInput
                id="make"
                ref="make"
                type="text"
                v-model="line_item_form.namePlateLineOne"

                class="block w-full"
            />
            <InputError class="mt-2"/>
          </div>
          <div class="col-span-6 sm:col-span-3 mt-2">
            <InputLabel for="make" value="Data-Plate Line 2"/>
            <TextInput
                id="make"
                ref="make"
                type="text"
                v-model="line_item_form.namePlateLineTwo"

                class="block w-full"
            />
            <InputError class="mt-2"/>
          </div>
          <div class="col-span-6 sm:col-span-3 mt-2">
            <InputLabel for="make" value="Data-Plate Line 3"/>
            <TextInput
                id="make"
                ref="make"
                type="text"
                v-model="line_item_form.namePlateLineThree"

                class="block w-full"
            />
            <InputError class="mt-2"/>
          </div>
          <div class="text-center mx-auto">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/LHQvNFVU5q8?si=8HNBYnetGDcVyT9x" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen class="ml-auto mr-auto mt-5"></iframe>
          </div>
        </div>
        <hr class="w-100 col-span-6 my-5">
        <div class="col-span-6 sm:col-span-3">
          <h2>Digitize Your AFM/POH?</h2>
          <InputLabel value="Many owners and operators don’t realize that their POH is unique to their aircraft and serial number. Supplements, weight and balance information, and more are unique to your aircraft. Performing an aircraft re-weigh (weight and balance) alone can be significantly more expensive than this service at $499.95/manual." />
          <div class="relative flex mt-2">
            <div class="flex h-5 items-center">
              <Checkbox name="add_physical_reorg" v-model="line_item_form.add_poh_digitization" :checked="line_item_form.add_poh_digitization" :disabled="line_item_form.processing" />
            </div>
            <div class="ml-3 text-sm">
              <label v-if="line_item_form.add_poh_digitization" class="font-medium text-gray-600 dark:text-slate-400">Digitize my POH / AFM</label>
              <label v-else class="font-medium text-gray-600 dark:text-slate-400">Do Not Worry About Digitizing My POH</label>
            </div>
          </div>
        </div>
        <hr class="w-100 col-span-6 my-5">
        <div class="col-span-6 sm:col-span-4">
          <h2>Additional Project Notes / Requests</h2>
          <textarea rows="3" v-model="line_item_form.notes_added_by_client" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-logix-green-500 focus:ring-logix-green-500 sm:text-sm" :disabled="line_item_form.processing"/>
        </div>
        <div class="bg-gray-50 px-4 py-3 text-right sm:px-6 mt-2">
          <PrimaryButton>Next</PrimaryButton>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import InputLabel from "../InputLabel.vue";
import TextInput from "../TextInput.vue";
import InputError from "../InputError.vue";
import {useForm} from "@inertiajs/inertia-vue3";
import PrimaryButton from "../PrimaryButton.vue";
import pickBy from "lodash/pickBy";
import {onMounted} from "vue";
import Checkbox from '@/Components/Checkbox.vue';
import binderPlateImage from "@a/binders/plate.png"

const props = defineProps({
  submittedAircraftInfo: Object
})

const emit = defineEmits(['lineItemFormSubmitted']);

const line_item_form = useForm({
  notes_added_by_client: '',
  add_physical_reorg: false,
  add_hardcopy_binder: false,
  add_poh_digitization: false,
  namePlateLineOne: props.submittedAircraftInfo.tail_number,
  namePlateLineTwo: `${props.submittedAircraftInfo.ac_year_of_manufacture} ${props.submittedAircraftInfo.make} ${props.submittedAircraftInfo.aircraft_model}` ,
  namePlateLineThree: `S/N: ${props.submittedAircraftInfo.serial_number}`,
})
const addLineItems = () => {
  line_item_form.processing = true;
  axios.post(route('hubspot-add-deal-items'),pickBy(line_item_form)).then((res)=>{
    line_item_form.processing = false;
    emit('lineItemFormSubmitted');

  });

}
</script>