<script setup>

import Checkbox from '@/Components/Checkbox.vue';
import InputLabel from "../InputLabel.vue";
import TextInput from "../TextInput.vue";
import InputError from "../InputError.vue";
import {useForm} from "@inertiajs/inertia-vue3";
import PrimaryButton from "../PrimaryButton.vue";
import pickBy from "lodash/pickBy";
import {onMounted} from "vue";

const contact_form = useForm({
    tail_number: '',
    first_name: '',
    last_name: '',
    referrer: '',
    email: '',
    phone: '',
    keep_me_updated: true,
    //password: '',
    //password_confirmation: '',
    use_case: 'owner_operator',
});

const emit = defineEmits(['aircraftInfoCollected','contactFormSubmitted']);

const submitContactForm = () => {
    // ajax call ?
    console.log('submit hs contacts');
    contact_form.processing = true;
    axios.post(route('sync-hubspot-contact-to-company'),pickBy(contact_form)).then((res)=>{
      contact_form.processing = false;
      if (res.data && res.data.contact_id){
        // move along
        emit('contactFormSubmitted',{contact_id: res.data.contact_id, tail_number: contact_form.tail_number, contact_form: contact_form});
      }
    }).catch((err)=>{
      alert('There was an issue with this form. PLANELOGIX has been notified.');
      contact_form.processing = false;
    });
}



const syncContact = () => {
    axios.post(route('sync-hubspot-contact-to-company'),pickBy(contact_form)).then((res)=>{

    }).catch((err)=>{
      
    });
}


const getFAAInfo = () => {
    console.log('getFAAInfo', contact_form.tail_number)
    axios.get(route('get-faa-info',{tail_number:contact_form.tail_number})).then((res)=>{

      if (res.data && res.data.serial_number){

        emit('aircraftInfoCollected',res.data, contact_form.tail_number);
      }
    })
}

onMounted(()=>{
  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);

  if( urlParams.has('referrer') ){
    contact_form.referrer = urlParams.get('referrer');
    console.log('got referrer ' , urlParams.get('referrer'))
  }


})

</script>

<template>
    <div class="overflow-hidden shadow sm:rounded-md">
        <div class="bg-white px-4 py-5 sm:p-6">
            <form @submit.prevent="submitContactForm()" id="hs_submitcontact">
                <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-3">
                        <div>
                            <InputLabel for="first_name" value="Aircraft Tail Number"/>
                            <TextInput
                                id="tail_number"
                                ref="tail_number"
                                :disabled="contact_form.processing"
                                type="text"
                                v-model="contact_form.tail_number"
                                class="block w-full uppercase"
                                required
                                @blur="getFAAInfo"
                            />
                            <InputError class="mt-2"/>
                        </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                        <InputLabel for="first_name" value="Use Case"/>
                        <select id="country"
                                v-model="contact_form.use_case"
                                :disabled="contact_form.processing"
                                name="country"
                                autocomplete="country-name"
                                class="border-gray-300 focus:border-logix-green focus:ring focus:ring-logix-green focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-slate-200 block w-full"
                        >
                            <option value="owner_operator" selected>Owner Operator</option>
                            <option value="flight_school">Flight School</option>
                            <option value="fleet_operations">Fleet Operations</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                        <div>
                            <InputLabel for="first_name" value="First Name"/>
                            <TextInput
                                id="first_name"
                                ref="firstNameInput"
                                :disabled="contact_form.processing"
                                type="text"
                                v-model="contact_form.first_name"
                                class="block w-full"
                                required
                            />
                            <InputError class="mt-2"/>
                        </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <div>
                            <InputLabel for="last_name" value="Last Name"/>
                            <TextInput
                                id="last_name"
                                ref="lastNameInput"
                                :disabled="contact_form.processing"
                                type="text"
                                class="block w-full"
                                v-model="contact_form.last_name"
                                required
                            />
                            <InputError class="mt-2"/>
                        </div>
                    </div>

                    <div class="col-span-6 sm:col-span-4">
                        <InputLabel for="email" value="Email"/>
                        <TextInput
                            id="email"
                            ref="email"
                            type="email"
                            :disabled="contact_form.processing"
                            v-model="contact_form.email"
                            required
                            @blur="syncContact"
                            class="block w-full"
                        />
                        <InputError class="mt-2"/>
                    </div>

                    <div class="col-span-6 sm:col-span-4">
                        <InputLabel for="phone" value="Phone"/>
                        <TextInput
                            id="phone"
                            ref="phone"
                            type="text"
                            :disabled="contact_form.processing"
                            v-model="contact_form.phone"
                            required
                            class="block w-full"
                        />
                        <InputError class="mt-2"/>
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                      <InputLabel value="Interest in hearing from PLANELOGIX?" />
                      <div class="relative flex mt-2">
                        <div class="flex h-5 items-center">
                          <Checkbox name="DoesNotApply" v-model="contact_form.keep_me_updated" :checked="contact_form.keep_me_updated" :disabled="contact_form.processing" />
                        </div>
                        <div class="ml-3 text-sm">
                          <label v-if="contact_form.keep_me_updated" class="font-medium text-gray-600 dark:text-slate-400">Keep me updated on the latest from PLANELOGIX</label>
                          <label v-else class="font-medium text-gray-600 dark:text-slate-400">Do not keep me updated on PLANELOGIX's roadmap, technological improvements, or marketing materials.</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-full">
                      <div>
                        <InputLabel for="referrer" value="Referrer (optional)"/>
                        <TextInput
                            id="referrer"
                            ref="referrer"
                            :disabled="contact_form.processing"
                            type="text"
                            v-model="contact_form.referrer"
                            class="block w-full"
                        />
                        <InputError class="mt-2"/>
                      </div>
                    </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6 mt-2">
                    <PrimaryButton type="submit" :disabled="contact_form.processing">Next</PrimaryButton>
                </div>
            </form>
        </div>

    </div>
</template>

<style scoped>

</style>
