<template>
  <div class="overflow-hidden shadow sm:rounded-md">

    <div class="bg-white px-4 py-5 sm:p-6">
      <form @submit.prevent="submitAircraftDetails()" id="hs_submitdeal">
      <div class="grid grid-cols-6 gap-6">
        <hr class="w-100 col-span-6 ">
        <legend class="w-100 col-span-6">Aircraft Details</legend>
        <div class="col-span-3">
          <div>
            <InputLabel for="tailNumber" value="Tail Number"/>
            <TextInput
                id="tailNumber"
                ref="tailNumber"
                type="text"
                required
                disabled
                v-model="aircraft_info_form.tail_number"
                class="col-span-2"
            />
            <InputError class="mt-2"/>
          </div>
        </div>
        <div class="col-span-3">
          <label for="aircraft_type" class="block text-sm font-medium text-gray-700">Aircraft Type</label>
          <select id="aircraft_engine_type" name="aircraft_engine_type"
                  v-model="aircraft_info_form.aircraft_type"
                  class="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-logix-green focus:outline-none focus:ring-logix-green">
            <option selected value="Fixed-Wing">Fixed-Wing</option>
            <option value="Helicopter">Helicopter</option>
          </select>
        </div>
        <div class="col-span-3">
          <div>
            <InputLabel for="ac_year_of_manufacture" value="Year of Manufacture"/>
            <TextInput
                id="ac_year_of_manufacture"
                ref="ac_year_of_manufacture"
                type="number"
                min="1915"
                max="2025"
                required
                v-model="aircraft_info_form.ac_year_of_manufacture"
                class="col-span-2"
            />
            <InputError class="mt-2"/>
          </div>
        </div>
        <div class="col-span-3">
          <div>
            <InputLabel for="ac_total_time" value="Aircraft Total Time"/>
            <TextInput
                id="ac_total_time"
                ref="ac_total_time"
                type="number"
                step="0.01"
                required
                v-model="aircraft_info_form.ac_total_time"
                class="col-span-2"
            />
            <InputError class="mt-2"/>
            <InputLabel class="text-sm italic" value="Estimate welcome"/>
          </div>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <InputLabel for="make" value="Aircraft Manufacturer"/>
          <TextInput
              id="make"
              ref="make"
              type="text"
              v-model="aircraft_info_form.make"
              required
              class="block w-full"
          />
          <InputError class="mt-2"/>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <InputLabel for="model" value="Aircraft Model"/>
          <TextInput
              id="model"
              ref="model"
              type="text"
              v-model="aircraft_info_form.aircraft_model"
              required
              class="block w-full"
          />
          <InputError class="mt-2"/>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <InputLabel for="serial_number" value="Serial Number"/>
          <TextInput
              id="serial_number"
              ref="serial_number"
              type="text"
              v-model="aircraft_info_form.serial_number"
              required
              class="block w-full"
          />
          <InputError class="mt-2"/>
        </div>

        <div class="col-span-3">
          <label for="aircraft_engine_type" class="block text-sm font-medium text-gray-700">Operating Category</label>
          <select id="aircraft_engine_type" name="aircraft_engine_type"
                  v-model="aircraft_info_form.aircraft_operating_category"
                  class="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-logix-green focus:outline-none focus:ring-logix-green">
            <option selected value="Part 91">Part 91</option>
            <option value="Part 121">Part 121</option>
            <option value="Part 135">Part 135</option>
          </select>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <InputLabel for="ac_home_base" value="Home Aiport Identifier"/>
          <TextInput
              id="ac_home_base"
              ref="ac_home_base"
              type="text"
              maxlength="5"
              v-model="aircraft_info_form.ac_home_base"
              required
              class="block w-full uppercase"
          />
          <InputError class="mt-2"/>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <InputLabel value="Is the aircraft kept in a hangar?" />
          <div class="relative flex mt-2">
            <div class="flex h-5 items-center">
              <Checkbox name="DoesNotApply" v-model="aircraft_info_form.ac_hangared" :checked="aircraft_info_form.ac_hangared" :disabled="aircraft_info_form.processing" />
            </div>
            <div class="ml-3 text-sm">
              <label v-if="aircraft_info_form.ac_hangared" class="font-medium text-gray-600 dark:text-slate-400">Is Hangared</label>
              <label v-else class="font-medium text-gray-600 dark:text-slate-400">Not Hangared</label>
            </div>
          </div>
        </div>
        <hr class="w-100 col-span-6 ">
        <legend class="w-100 col-span-6">Engine Information</legend>
        <div class="col-span-3">
          <InputLabel for="aircraft_number_of_engines" value="# of Engines"/>
          <TextInput
              id="aircraft_number_of_engines"
              ref="aircraft_number_of_engines"
              v-model="aircraft_info_form.aircraft_number_of_engines"
              type="number"
              max="5"
              min="1"
              class="block w-[75%]"
          />
          <InputError class="mt-2"/>

        </div>
        <div class="col-span-3">
          <label for="aircraft_engine_type" class="block text-sm font-medium text-gray-700">Engine Type</label>
          <select id="aircraft_engine_type" name="aircraft_engine_type"
                  v-model="aircraft_info_form.aircraft_engine_type"
                  class="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-logix-green focus:outline-none focus:ring-logix-green">
            <option selected value="Piston">Piston</option>
            <option value="Turbo-Prop">Turboprop</option>
            <option value="Turbo-Shaft">Turboshaft</option>
            <option value="Jet">Jet</option>
            <option value="None- Glider">None- Glider</option>
            <option value="Electric">Electric</option>
          </select>
        </div>
        <div class="col-span-3" v-if="aircraft_info_form.aircraft_engine_type === 'Piston'">
          <label for="aircraft_engine_type" class="block text-sm font-medium text-gray-700">Turbo-System</label>
          <select id="aircraft_engine_type" name="aircraft_engine_type"
                  v-model="aircraft_info_form.aircraft_piston_turbo_system_type"
                  class="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-logix-green focus:outline-none focus:ring-logix-green">
            <option selected value="None">None</option>
            <option value="Turbo-Charged">Turbo-Charged</option>
            <option value="Turbo-Normalized">Turbo-Normalized</option>
          </select>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <InputLabel for="engine_manufacturer" value="Engine Manufacturer"/>
          <TextInput
              id="engine_manufacturer"
              ref="engine_manufacturer"
              type="text"
              v-model="aircraft_info_form.engine_manufacturer"
              required
              class="block w-full"
          />
          <InputError class="mt-2"/>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <InputLabel for="engine_model" value="Engine Model"/>
          <TextInput
              id="engine_model"
              ref="engine_model"
              type="text"
              v-model="aircraft_info_form.engine_model"
              required
              class="block w-full"
          />
          <InputError class="mt-2"/>
        </div>

        <!-- engine times -->
        <div class="col-span-6 sm:col-span-3" v-for="engineIndex in parseInt(aircraft_info_form.aircraft_number_of_engines)" :key="engineIndex">
          <InputLabel for="numberOfEngines" :value="'Engine No.' + engineIndex + ' Time Since New/OH'"/>
          <TextInput
              v-model="aircraft_info_form.engineTimes[engineIndex - 1]"
              type="number"
              required
          />
          <InputError class="mt-2"/>
          <InputLabel class="text-sm italic" value="Estimates welcome"/>
        </div>
      </div>

      <div class="bg-gray-50 px-4 py-3 text-right sm:px-6 mt-2">
        <PrimaryButton >Next</PrimaryButton>
      </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import InputLabel from "../InputLabel.vue";
import TextInput from "../TextInput.vue";
import InputError from "../InputError.vue";
import {useForm} from "@inertiajs/inertia-vue3";
import PrimaryButton from "../PrimaryButton.vue";
import pickBy from "lodash/pickBy";
import {onMounted} from "vue";
import Checkbox from '@/Components/Checkbox.vue';

const props = defineProps({
  aircraftInfo: Object,
  providedTailNumber: String,
  hs_contact_id: String, // hubspot contact ID
})

const aircraft_info_form = useForm({
  hs_contact_id: props.hs_contact_id,
  tail_number: props.providedTailNumber,
  make: '',
  model: '',
  serial_number: '',
  aircraft_type: 'Fixed-Wing',
  aircraft_engine_type: 'Piston',
  aircraft_piston_turbo_system_type: 'None',
  aircraft_operating_category: 'Part 91',
  engine_manufacturer: '',
  engine_model: '',
  aircraft_number_of_engines: 1,
  ac_total_time: null,
  ac_year_of_manufacture: null,
  ac_home_base: null,
  ac_hangared: true,
  engineTimes: [],
});

onMounted(() => {
    if (props.aircraftInfo){
      aircraft_info_form.aircraft_type = props.aircraftInfo.aircraft_type;
      aircraft_info_form.tail_number = 'N'+props.aircraftInfo.tail_number;
      aircraft_info_form.make = props.aircraftInfo.mfr;
      aircraft_info_form.aircraft_model = props.aircraftInfo.model;
      aircraft_info_form.serial_number = props.aircraftInfo.serial_number;
      aircraft_info_form.engine_manufacturer = props.aircraftInfo.faa_aircraft_engine_mfr;
      aircraft_info_form.engine_model = props.aircraftInfo.faa_aircraft_engine_model;
      aircraft_info_form.aircraft_number_of_engines = parseInt(props.aircraftInfo.no_eng);

      if (props.aircraftInfo.year_mfr){
        aircraft_info_form.ac_year_of_manufacture = props.aircraftInfo.year_mfr;
      }

      if (props.aircraftInfo.faa_engine_type){

        if (props.aircraftInfo.faa_engine_type === 'Reciprocating'){
          aircraft_info_form.aircraft_engine_type = 'Piston';

          if ((props.aircraftInfo.faa_aircraft_engine_model).includes("TSIO")){
            aircraft_info_form.aircraft_piston_turbo_system_type = 'Turbo-Charged';
          }

        }
        else if (props.aircraftInfo.faa_engine_type === 'Turbo-prop'){
          aircraft_info_form.aircraft_engine_type = 'Turbo-Prop';
        }
        else if (props.aircraftInfo.faa_engine_type === 'Turbo-shaft'){
          aircraft_info_form.aircraft_engine_type = 'Turbo-Shaft';
        }
        else if (props.aircraftInfo.faa_engine_type === 'Turbo-jet' || props.aircraftInfo.faa_engine_type === 'Turbo-fan' || props.aircraftInfo.faa_engine_type === 'ramjet'){

          aircraft_info_form.aircraft_engine_type = 'Jet';
        }
        else {
          aircraft_info_form.aircraft_engine_type = 'Piston';
        }

      }
    }

})

const emit = defineEmits(['aircraftFormSubmitted']);

const submitAircraftDetails = () => {
  aircraft_info_form.processing = true;
  axios.post(route('hubspot-create-deal'),pickBy(aircraft_info_form)).then((res)=>{
    aircraft_info_form.processing = false;
    if (res.data && res.data.deal_id){
      // move along
      emit('aircraftFormSubmitted',{dealID: res.data.deal_id, submittedAircraftInfo: aircraft_info_form})
    }
  });

}

</script>