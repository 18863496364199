<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import ApplicationLogo from '@/Components/ApplicationLogo.vue'
import OnboardingForm from "./OnboardingForm.vue";

const props = defineProps({
    open: false
})

const emit = defineEmits(['close'])

const closeOnboardingForm = () => {
    console.log('onboarding form closeonboarding form');
    emit('close',false);
}

</script>

<template>
    <TransitionRoot as="template" :show="open" >
        <Dialog as="div" class="relative z-10 overflow-auto" @close="closeOnboardingForm()">
            <div class="fixed inset-0" />
            <div class="fixed inset-0">
                <div class="absolute inset-0">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                        <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen ">
                                <div class="flex h-full flex-col bg-gray-900 py-6 shadow-xl overflow-y-auto">
                                    <div class="px-4 sm:px-6">
                                        <div class="flex items-start justify-between">
                                            <DialogTitle class="text-base font-semibold leading-6 ">
                                                <h1 class="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                                    <span class="">Protect Your </span>
                                                    <span class="bg-gradient-to-r from-logix-green to-emerald-700 bg-clip-text pb-3 text-transparent sm:pb-5">Aircraft Value</span>
                                                </h1>
                                                <div class="text-stone-400">
                                                    <p class="mt-5">PLANELOGIX provides the absolute best in customer service and aircraft record management.</p><br>
                                                    <p>Being a PLANELOGIX customer means never worrying about losing your logbooks or keeping up with maintenance.</p><br>
                                                    <p>Simply fill out the form below, and <strong>join us!</strong></p>
                                                </div>

                                            </DialogTitle>
                                            <div class="ml-3 flex h-7 items-center">
                                                <button type="button" class="relative rounded-md bg-logix-dark-blue text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-logix-green-500 focus:ring-offset-2" @click="closeOnboardingForm()">
                                                    <span class="absolute -inset-2.5" />
                                                    <span class="sr-only">Close panel</span>
                                                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- form -->
                                    <div class="grow my-5">
                                        <OnboardingForm></OnboardingForm>
                                    </div>
                                    <!-- footer -->
                                    <div class="flex justify-end mt-5 min-h-[100px] p-5">
                                        <span class="sr-only">PLANELOGIX</span>
                                        <application-logo svg-style="width:5rem !important;" svg-class="fill-logix-green stroke-logix-green stroke-logix-green" svg-stroke="stroke-logix-green"></application-logo>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<style scoped>

</style>
