<script setup>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3';
import {watch, ref, onMounted} from 'vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import Multiselect from "@vueform/multiselect";

const props = defineProps({
  countries: Object,
  version: null,
  aircraftInfo: {
    tail_number: "",
    make: "",
    model: "",
    serial_number: "",
    aircraft_type: "",
    aircraft_engine_type: "",
    aircraft_piston_turbo_system_type: "",
    aircraft_operating_category: "",
    engine_manufacturer: "",
    engine_model: "",
    aircraft_number_of_engines: 1,
    ac_total_time: "",
    ac_year_of_manufacture: 1985,
    ac_home_base: "",
    ac_hangared: true,
    engineTimes: [],
    aircraft_model: ""
  },
  contactInfo: {
    tail_number: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    keep_me_updated: true,
    use_case: '',
  }
});

const form = useForm({
  tail: props.aircraftInfo.tail_number,
  country_id: 1,
  name: props.contactInfo.first_name,
  last_name: props.contactInfo.last_name,
  email: props.contactInfo.email,
  password: '',
  password_confirmation: '',
  terms: false,
  version: props.version,
  engineType: 'Piston',
  numberOfEngines: props.aircraftInfo.aircraft_number_of_engines,
});

const submit = () => {
  let url = props.path === '' || props.path === undefined ? route('register') : props.path;
  form.post(url, {
    onFinish: () => form.reset('password', 'password_confirmation'),
  });
};

onMounted(()=>{

  if (props.aircraftInfo.aircraft_engine_type){

    if (props.aircraftInfo.aircraft_engine_type === 'Piston'){
      form.engineType = 'Piston';
    } else if (props.aircraftInfo.aircraft_engine_type === 'Turbo-Prop'){
      form.engineType = 'Turboprop';
    } else if (props.aircraftInfo.aircraft_engine_type === 'Jet'){
      form.engineType = 'Jet';
    }
  }

})

</script>

<template>
  <div class="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg ml-auto mr-auto" >
    <h3>Please Provide a Password to Complete Sign-Up</h3>
    <form @submit.prevent="submit">
      <div class="mt-4">
        <InputLabel for="password" value="Password" />
        <TextInput
            id="password"
            v-model="form.password"
            type="password"
            class="mt-1 block w-full"
            required
            autocomplete="new-password"
        />
        <InputError class="mt-2" :message="form.errors.password" />
      </div>

      <div class="mt-4">
        <InputLabel for="password_confirmation" value="Confirm Password" />
        <TextInput
            id="password_confirmation"
            v-model="form.password_confirmation"
            type="password"
            class="mt-1 block w-full"
            required
            autocomplete="new-password"
        />
        <InputError class="mt-2" :message="form.errors.password_confirmation" />
      </div>

      <div v-if="$page.props.jetstream.hasTermsAndPrivacyPolicyFeature" class="mt-4">
        <InputLabel for="terms">
          <div class="flex items-center">
            <Checkbox id="terms" v-model:checked="form.terms" name="terms" required />

            <div class="ml-2">
              I agree to the <a target="_blank" :href="route('terms.show')" class="underline text-sm text-gray-600 hover:text-gray-900">Terms of Service and Privacy Policy</a>
            </div>
          </div>
          <InputError class="mt-2" :message="form.errors.terms" />
        </InputLabel>
      </div>

      <div v-if="form.errors">
          <div v-for="error in form.errors">
            <InputError class="mt-2" :message="error" />
          </div>
      </div>

      <div class="flex items-center justify-end mt-4">
        <PrimaryButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
          Register
        </PrimaryButton>
      </div>
    </form>
  </div>

</template>

<style scoped>
@import '#/@vueform/multiselect/themes/tailwind.css';
</style>