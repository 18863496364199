<script setup>
import { Head, Link } from '@inertiajs/inertia-vue3';
import Banner from '@/Components/Banner.vue';
import ApplicationLogo from '@/Components/ApplicationLogo.vue'
import {computed, onMounted, ref, useSlots, watch, onBeforeUnmount} from 'vue';
import favicon from '/resources/assets/logo/favicon.ico';
import SlideOut from "@/Components/HubSpot/SlideOut.vue";


const props = defineProps({
    canLogin: Boolean,
    canRegister: Boolean,
    openOnboardingForm: Boolean,
    appVersion: String,
    releaseStage: String,
    projectQueueStatus: String,
    title: String,
});


import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import {
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/vue/24/outline'
import { ArrowTopRightOnSquareIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import {defineComponent, h} from "vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";


const navigation = [
    { name: 'Product', href: '/product' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'Case Studies', href: '/case-studies' },
    //{ name: 'Sign-Up', href: '/sign-up' },
    { name: 'Company', href: '/about' },
    { name: 'Listings', href: '/listings' },
    { name: 'Contact', href: '/contact' },
]

const checkIfViewable = (item) => {

}

const socialLink = (url) =>{
    window.open(url, '_blank');
}

const footerNavigation = {
    solutions: [
        { name: 'Product', href: '/product' },
        { name: 'Pricing', href: '/pricing' },
        { name: 'Sign-Up', href: '/register' },
        { name: 'Listings', href: '/listings' },
        //{ show: false },
        /*{ name: 'General Aviation', href: '#' },
        { name: 'Business Aviation', href: '#' },
        { name: 'Government', href: '#' },
        { name: 'Military', href: '#' },*/
    ],
    support: [
        { show: false },
        { name: 'Pricing', href: '#' },
        { name: 'Documentation', href: '#' },
        { name: 'Guides', href: '#' },
        { name: 'API Status', href: '#' },
    ],
    company: [
        { name: 'About', href: '/about' },
        { name: 'Blog', href: '/blog' },
        { name: 'Case Studies', href: '/case-studies' },
        { name: 'Contact', href: '/contact' },
        //{ name: 'Partners', href: '/about#partners' },
    ],
    legal: [
        //{ name: 'Privacy', href: '#' },
        { name: 'Terms', href: '/terms' },
        { name: 'Privacy Policy', href: '/privacy-policy' },
    ],
    social: [
        {
            name: 'Facebook',
            href: 'https://www.facebook.com/planelogix',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'Instagram',
            href: 'https://www.instagram.com/planelogix',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'Twitter',
            href: 'https://twitter.com/planelogix',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            d: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
                        }),
                    ]),
            }),
        },
        {
            name: 'LinkedIn',
            href: 'https://www.linkedin.com/company/planelogix',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            d: 'M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z',
                        }),
                    ]),
            }),
        },
    ],
}

const showOnboardingForm = ref(false)

const emit = defineEmits(['openOnboardingForm','onboardingFormClosed']);

watch(() => props.openOnboardingForm, (newVal,oldVal) => {
  if (newVal === true){
    showOnboardingForm.value = true;
  }
})
const openOnboardingForm = () => {
    emit('openOnboardingForm');
    showOnboardingForm.value = true;
};

const HUBSPOT_SCRIPT_ID = 'hs-script-loader'

const addHubspotScript = () => {
    if (!document.getElementById(HUBSPOT_SCRIPT_ID)) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.id = HUBSPOT_SCRIPT_ID
        script.async = true
        script.defer = true
        script.src = '//js-na1.hs-scripts.com/44301840.js'
        document.body.appendChild(script)
    }
};

const removeHubspotScript= () => {
    const script = document.getElementById(HUBSPOT_SCRIPT_ID)
    if (script) {
        script.remove()
    }

    // Remove HubSpot chat iframe
    const hubspotChat = document.querySelector('iframe[src*="js-na1.hs-scripts.com"]')
    if (hubspotChat) {
        hubspotChat.remove()
    }

    // Remove any widgets or overlays created by HubSpot
    const hubspotWidgets = document.querySelectorAll('[id^="hubspot-messages-iframe"], .hubspot-link__container, .hs-form')
    hubspotWidgets.forEach(el => el.remove())
}

onMounted(()=>{
  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);

  if( urlParams.has('onboard_flow') ){
    showOnboardingForm.value = true;
  }

  if(props.canLogin){
      addHubspotScript()
  }else{
        removeHubspotScript()
  }
})

onBeforeUnmount(() => {
    removeHubspotScript()
})
</script>

<template @openOnboardingForm="openOnboardingForm">
    <div>
        <Head :title="title">
            <link rel="icon" :href="favicon" />
        </Head>

        <Banner/>
        <SlideOut
            :open="showOnboardingForm"
            @close="showOnboardingForm = !showOnboardingForm; emit('onboardingFormClosed')"
        ></SlideOut>
        <div class="bg-white">
            <div class="relative overflow-hidden">
                <Popover as="header" class="relative">
                    <div class="bg-gray-900 pt-3 pb-3">
                        <nav class="relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6" aria-label="Global">
                            <div class="flex flex-1 items-center">
                                <div class="flex w-full items-center justify-between md:w-auto">
                                    <Link href="/">
                                        <span class="sr-only">PLANELOGIX</span>
                                        <application-logo svg-style="width:5rem !important;" svg-class="fill-logix-green stroke-logix-green stroke-logix-green" svg-stroke="stroke-logix-green"></application-logo>
<!--                                        <img class="h-8 w-auto sm:h-10" src="https://tailwindui.com/img/logos/mark.svg?from-color=teal&from-shade=200&to-color=cyan&to-shade=400&toShade=400" alt="" />-->
                                    </Link>
                                    <div class="-mr-2 flex items-center md:hidden">
                                        <PopoverButton class="focus-ring-inset inline-flex items-center justify-center rounded-md bg-gray-900 p-2 text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white">
                                            <span class="sr-only">Open main menu</span>
                                            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                                        </PopoverButton>
                                    </div>
                                </div>
                                <div class="hidden space-x-8 md:ml-10 md:flex">
                                    <Link v-for="item in navigation" :key="item.name"
                                          :href="item.href" v-show="!($page.props.user && item.name === 'Sign-Up')" class="text-base font-medium text-white hover:text-gray-300">{{ item.name }}</Link>
                                </div>
                            </div>
                            <div class="hidden md:flex md:items-center md:space-x-6" v-if="$page.props.canLogin">

                                <Link v-if="$page.props.user && $page.props.user.IsTranscriptionist" :href="route('aircraft-dashboard',$page.props.user.current_team.id)" class="text-sm text-pink-400 font-bold dark:text-pink-900">Back to Dashboard</Link>
                                <Link v-else-if="$page.props.user && $page.props.user.current_team" :href="route('aircraft-dashboard',$page.props.user.current_team.id)" class="text-sm text-logix-green font-bold dark:text-gray-500">Back to Dashboard</Link>
                                <Link v-else-if="$page.props.user && !$page.props.user.current_team && !$page.props.user.isPlanelogixTeamMember" :href="route('empty-dashboard')" class="text-sm text-logix-green font-bold dark:text-gray-500">Back to Dashboard</Link>
                                <Link v-else-if="$page.props.user && $page.props.user.isPlanelogixTeamMember" :href="route('nova.pages.home')" class="text-sm text-logix-green font-bold dark:text-gray-500">Back to Dashboard</Link>
                                <template v-else>
                                    <Link :href="route('login')" class="text-base font-medium text-white hover:text-gray-300">Log in</Link>

                                    <primary-button @click.prevent="openOnboardingForm" class="h-10"><h3>Start PLANELOGIX</h3></primary-button>
<!--                                    <Link v-if="$page.props.canRegister" :href="route('register')" class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white hover:bg-gray-700">Sign-Up // Request Service</Link>-->
                                </template>
                            </div>
                        </nav>
                    </div>

                    <transition enter-active-class="duration-150 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
                        <PopoverPanel focus class="z-10 absolute inset-x-0 top-0 origin-top transform p-2 transition md:hidden">
                            <div class="overflow-hidden rounded-lg bg-logix-dark-blue text-white shadow-md ring-1 ring-black ring-opacity-5">
                                <div class="flex items-center justify-between px-5 pt-4">
                                    <div>
                                        <application-logo svg-style="width:5rem !important;" svg-class="fill-logix-green stroke-logix-green stroke-logix-green" svg-stroke="stroke-logix-green"></application-logo>
                                    </div>
                                    <div class="-mr-2">
                                        <PopoverButton class="inline-flex items-center justify-center rounded-md bg-logix-green p-2 text-black hover:text-white hover:bg-blue-950 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                                            <span class="sr-only">Close menu</span>
                                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                        </PopoverButton>
                                    </div>
                                </div>
                                <div class="pt-5 pb-6">
                                    <div class="space-y-1 px-2">
                                        <Link v-for="item in navigation" :key="item.name" :href="item.href" class="block rounded-md px-3 py-2 text-base font-medium  hover:bg-logix-green">{{ item.name }}</Link>
                                    </div>
                                    <div class="mt-6 px-5 ml-auto mr-auto text-center">
                                      <primary-button @click="openOnboardingForm()" class="h-10"><h3>Start your PLANELOGIX service <em><strong>TODAY</strong></em></h3></primary-button>

                                      <!--                                        <Link :href="route('register',{email:email})" class="block w-full rounded-md bg-gradient-to-r from-logix-green to-logix-green py-3 px-4 text-center font-medium text-white shadow hover:from-logix-green hover:to-green-800">Start free trial</Link>-->
                                    </div>
                                    <div class="mt-6 px-5">
                                        <p class="text-center text-base font-medium text-gray-400">Existing customer? <Link href="/login" class="text-gray-400 font-bold hover:underline">Login</Link></p>
                                    </div>
                                </div>
                            </div>
                        </PopoverPanel>
                    </transition>
                </Popover>
                <main>
                    <slot />
                </main>
                <footer class="bg-gray-50" aria-labelledby="footer-heading">
                    <h2 id="footer-heading" class="sr-only">Footer</h2>
                    <div class="mx-auto max-w-md px-4 pt-12 sm:max-w-7xl sm:px-6 lg:px-8 lg:pt-16">
                        <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                            <div class="space-y-8 xl:col-span-1">
                                <application-logo  svg-style="width:5rem !important;" svg-class="fill-gray-500 stroke-gray-500 stroke-gray-500" svg-stroke="stroke-gray-500"></application-logo>
                                <p class="text-base text-gray-500">Protect Your Aircraft Value</p>
                                <div class="flex space-x-6">
                                    <a v-for="item in footerNavigation.social" :key="item.name"  @click.prevent="socialLink(item.href)" :href="item.href" class="cursor-pointer text-gray-400 hover:text-gray-500">
                                        <span class="sr-only">{{ item.name }}</span>
                                        <component :is="item.icon" :class="[item.name === 'LinkedIn' ? 'h-5 w-6' : 'h-6 w-6']" aria-hidden="true" />
                                    </a>
                                </div>
                            </div>
                            <div class="mt-12 grid grid-cols-1 gap-8 xl:col-span-2 xl:mt-0">
                                <div class="md:grid md:grid-cols-2 md:gap-8">

                                    <!--<div class="mt-12 md:mt-0">
                                        <h3 class="text-base font-medium text-gray-900">Support</h3>
                                        <ul role="list" class="mt-4 space-y-4">
                                            <li v-for="item in footerNavigation.support" :key="item.name">
                                                <Link :href="item.href" class="text-base text-gray-500 hover:text-gray-900">{{ item.name }}</Link>
                                            </li>
                                        </ul>
                                    </div>-->
                                </div>
                                <div class="md:grid md:grid-cols-3 md:gap-8">
                                    <div>
                                        <h3 class="text-base font-medium text-gray-900">Solutions</h3>
                                        <ul role="list" class="mt-4 space-y-4">
                                            <li v-for="item in footerNavigation.solutions" :key="item.name">
                                                <Link :href="item.href" class="text-base text-gray-500 hover:text-gray-900">{{ item.name }}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h3 class="mt-12 md:mt-0 text-base font-medium text-gray-900">Company</h3>
                                        <ul role="list" class="mt-4 space-y-4">
                                            <li v-for="item in footerNavigation.company" :key="item.name">
                                                <Link v-if="item.href !== '/blog'" :href="item.href" class="text-base text-gray-500 hover:text-gray-900">{{ item.name }}</Link>
                                                <a v-else href="https://blog.planelogix.com" target="_blank" class="text-base text-gray-500 hover:text-gray-900">Blog</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="mt-12 md:mt-0">
                                        <h3 class="text-base font-medium text-gray-900">Legal</h3>
                                        <ul role="list" class="mt-4 space-y-4">
                                            <li v-for="item in footerNavigation.legal" :key="item.name">
                                                <Link :href="item.href" class="text-base text-gray-500 hover:text-gray-900">{{ item.name }}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-12 border-t border-gray-200 py-8">
                            <p class="text-base text-gray-400 text-center">&copy; {{new Date().getFullYear()}} PLANELOGIX, Inc. All rights reserved.</p>
                            <p class="text-base text-gray-400 text-center">(919) 903-9390</p>
                            <p class="text-base text-gray-400 text-center">Application Version {{$page.props.appVersion}}</p>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>
